/* eslint-disable @typescript-eslint/no-floating-promises */
import GET_REPORT_EMBED_TOKEN from "@/graphql/queries/report/getReportEmbedToken";
import { withoutNulls } from "@/utils/withoutNulls";
import { useLazyQuery } from "@apollo/client";
import { useEffect, useMemo } from "react";

export default function useReportEmbedToken() {
  const [getReportEmbedToken, { data: raw, loading, error }] = useLazyQuery(
    GET_REPORT_EMBED_TOKEN
  );

  const normalized = useMemo(() => {
    const data = withoutNulls(raw?.reportEmbedToken);
    return data;
  }, [raw]);

  useEffect(() => {
    getReportEmbedToken();
  }, [getReportEmbedToken]);

  return { data: normalized, loading, error };
}
