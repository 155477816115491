import { memo } from "react";

import * as Styled from "./styles";
import Tab from "./Tab";
import type { Props } from "./types";

function Tabs({
  activeTab,
  className,
  handleOnClick,
  tabs,
}: Props): JSX.Element {
  return (
    <Styled.Container className={className}>
      {tabs.map((tab) => (
        <Tab
          key={`${tab.title}${tab.id}`}
          title={tab.title}
          id={tab.id}
          isActive={activeTab === `${tab.id}`}
          handleOnClick={handleOnClick}
          value={tab.id}
        />
      ))}
    </Styled.Container>
  );
}

export default memo(Tabs);
