import IconHeaderCTA from "@/components/IconHeaderCTA";
import buildings from "@/assets/images/illustrations/buildings.png";
import Image from "next/image";
import * as Styled from "./styles";

const ErrorView = () => (
  <IconHeaderCTA
    illustration={
      <Image src={buildings.src} alt="Error image" width={342} height={200} />
    }
    header="No one is home! (this page couldn’t load)"
  >
    <Styled.Description>
      <p>Our apologies! A few tips to troubleshoot:</p>
      <Styled.List>
        <Styled.ListItem>
          <Styled.ListLink
            href=""
            onClick={(e) => {
              e.preventDefault();
              window.location.reload();
            }}
          >
            Refresh this page
          </Styled.ListLink>
        </Styled.ListItem>
        <Styled.ListItem>
          <Styled.ListLink href="/">Go back to Renew home</Styled.ListLink>
        </Styled.ListItem>
      </Styled.List>
      <p>
        Questions or need help? Drop us a line at{" "}
        <Styled.Link href="mailto:propertysupport@heyrenew.com">
          propertysupport@heyrenew.com
        </Styled.Link>
      </p>
    </Styled.Description>
  </IconHeaderCTA>
);

export default ErrorView;
