import styled from "@emotion/styled";
import defaultTheme from "@/styles/themes/default";
import { TypographyStyles } from "@/components/Typography";
import UtilizeSpacer from "@/styles/theme-styles";

export const Description = styled.div`
  text-align: center;

  line-height: 24px;
  ${UtilizeSpacer({
    specificOption: {
      size: "3xs",
      direction: "top",
    },
    type: "margin",
  })}
  p {
    color: ${defaultTheme.renewTheme.colors.black};
  }
`;
export const List = styled.ul`
  ${UtilizeSpacer({
    specificOption: {
      size: "xs",
      direction: "vertical",
    },
    type: "margin",
  })}
`;

export const ListItem = styled.li`
  list-style-position: inside;
`;

export const ListLink = styled.a`
  color: ${defaultTheme.renewTheme.colors.night400};
`;

export const Link = styled(ListLink)`
  ${TypographyStyles("text", "md", "bold")}
`;
