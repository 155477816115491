import Loader from "@/components/Loader";
import useReportEmbedToken from "@/graphql/hooks/report/useReportEmbedToken";
import useCurrentUser from "@/graphql/hooks/user/useUser";
import { useEffect, useState } from "react";
import { useAnalytics } from "@/providers/AnalyticsProvider";
import { useRouter } from "next/router";
import ErrorView from "../Error";
import * as Styled from "./styles";

const Reports = ({ loginOccurred }: { loginOccurred?: boolean }) => {
  const { data: reportEmbedToken, error } = useReportEmbedToken();
  const { data: userData } = useCurrentUser();
  const analytics = useAnalytics();
  const router = useRouter();
  const [loginTracked, setLoginTracked] = useState(false);

  useEffect(() => {
    if (loginOccurred && userData?.email && !loginTracked) {
      void analytics.track("partner_app.user.login", {
        email: userData?.email,
        property_manager: userData?.propertyManager?.name,
        role: userData?.role,
        communities: userData?.communities?.map((c) => c.id),
      });
      void router.replace("/reports", undefined, { shallow: true });
      setLoginTracked(true);
    }
  }, [loginOccurred, loginTracked, userData, analytics, router]);

  const getData = () => {
    if (
      error ||
      reportEmbedToken?.__typename === "InvalidReportEmbedTokenError"
    ) {
      return <ErrorView />;
    }
    if (reportEmbedToken?.__typename === "ReportEmbedToken") {
      const iFrameData = `<html>
      <body>
           <form id="form" action="${reportEmbedToken.url}" method="post">
           <input type="hidden" name="embedToken" value="${reportEmbedToken.token}">
           </form>
           <script>
           document.getElementById("form").submit();
           </script>
       </body>
     </html>`;

      return (
        <Styled.View
          id="iframe"
          width="100%"
          height="100%"
          scrolling="yes"
          frameBorder="0"
          srcDoc={iFrameData}
          onError={() => <ErrorView />}
        />
      );
    }
    return <Loader />;
  };
  return <Styled.Container>{getData()}</Styled.Container>;
};

export default Reports;
