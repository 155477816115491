import { from } from "@/styles/responsive";
import { css } from "@emotion/react";

import defaultTheme from "@/styles/themes/default";
import UtilizeSpacer from "@/styles/theme-styles";
import styled from "@emotion/styled";
import type { $ContainerProps } from "./types";

export const Container = styled.li<$ContainerProps>`
  display: inline-block;
  list-style-type: none;

  ${UtilizeSpacer({
    options: [
      {
        size: "0",
        direction: [0, 2, 3],
      },
      {
        size: "sm",
        direction: [1],
      },
    ],
    type: "margin",
  })}

  &::after {
    border-radius: 0.125rem;
    content: "";
    display: block;
    height: 0.125rem;
    transition: background-color 100ms ease-in;
  }
  ${({ $isActive }) =>
    $isActive &&
    css`
      &::after {
        background-color: ${defaultTheme.renewTheme.colors.night400};
      }
    `};
`;

export const Button = styled.button<$ContainerProps>`
  background: none;
  border: none;
  color: ${defaultTheme.renewTheme.colors.grey500};
  cursor: pointer;
  font-size: 0.938rem;
  font-weight: ${defaultTheme.renewTheme.weights.medium}; //semibold
  letter-spacing: 0.03em;
  line-height: 1.5rem;
  ${UtilizeSpacer({
    options: [
      {
        size: "xs",
        direction: [0],
      },
      {
        size: "0",
        direction: [1],
      },
    ],
    type: "padding",
  })}

  ${({ $isActive }) =>
    $isActive &&
    css`
      color: ${defaultTheme.renewTheme.colors.night400};
    `}

  ${from.tabletLandscape`
    font-size: 1.125rem;
  `};
`;
