import styled from "@emotion/styled";
import PageHeader from "@/components/PageHeader";
import UtilizeSpacer from "@/styles/theme-styles";
import Tabs from "@/components/Tabs";

export const Container = styled.div``;

export const Header = styled(PageHeader)`
  ${UtilizeSpacer({
    specificOption: {
      size: "md",
      direction: "bottom",
    },
    type: "padding",
  })}
`;

export const ReportTabs = styled(Tabs)`
  ${UtilizeSpacer({
    specificOption: {
      size: "md",
      direction: "bottom",
    },
    type: "padding",
  })}
`;

export const View = styled.iframe`
  height: 100vh;
  border-radius: 12px;
`;
