import type { ReactElement } from "react";
import Layout from "@/layouts/WithNav";
import ReportsView from "@/views/Report";
import type { NextPageWithLayout } from "../_app";

const Reports: NextPageWithLayout = ({
  loginOccurred,
}: {
  loginOccurred?: boolean;
}) => <ReportsView loginOccurred={loginOccurred} />;

Reports.getLayout = function getLayout(page: ReactElement) {
  return <Layout>{page}</Layout>;
};

export default Reports;
