import { ReportEmbedTokenQuery } from "@/graphql/generated";
import { gql, TypedDocumentNode } from "@apollo/client";

const GET_REPORT_EMBED_TOKEN: TypedDocumentNode<ReportEmbedTokenQuery> = gql`
  query reportEmbedToken {
    reportEmbedToken {
      ... on ReportEmbedToken {
        __typename
        token
        url
      }
      ... on InvalidReportEmbedTokenError {
        __typename
        message
      }
    }
  }
`;

export default GET_REPORT_EMBED_TOKEN;
