import { memo } from "react";

import * as Styled from "./styles";
import type { Props } from "./types";

function Tab({
  className,
  id,
  isActive,
  title,
  handleOnClick,
  value,
}: Props): JSX.Element {
  return (
    <Styled.Container
      className={className}
      id={id}
      $isActive={isActive}
      role="tab"
    >
      <Styled.Button onClick={handleOnClick} value={value} $isActive={isActive}>
        {title}
      </Styled.Button>
    </Styled.Container>
  );
}

export default memo(Tab);
